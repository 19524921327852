<template>
  <div class="leader mt-10" :style="`background-color: ${theme.lightColor};`">
    <div class="content pt-9 pb-3">
      <div
        class="brq pl-2"
        :style="`border-left: ${theme.midColor} 6px solid;`"
      >
        <div class="sub-title">导读</div>
      </div>
      <div class="leader-text my-5" v-html="leader"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageLead",
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    leader: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 1.5rem;
  text-align: justify;
}
.leader {
  width: 100%;
  min-height: 5rem;
  letter-spacing: 0.2rem;
  .sub-title {
    line-height: 1;
    font-size: 1.1rem;
  }
  .leader-text {
    color: #606060;
    font-size: 0.85rem;
    text-align: justify;
    word-wrap: break-word;
  }
}
</style>
